<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {

    }
  },
  created() {
    console.log(`==> DEBUG ==> created`)
  },
  mounted() {
    console.log(`==> DEBUG ==> mounted`)
    this.init()
  },
  methods: {
    init() {
      console.log(`==> DEBUG ==> methods.init()`)
    }
  }
}
</script>

<style lang="scss">
$width: 100vw;
#app {
  width: $width;
  margin: 0 auto;
}
</style>